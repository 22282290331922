import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #fff;
    color: #323232;
    margin: 0;
    font-size: 1.7rem;
    font-family: "Lato", sans-serif;

    @media only screen and (min-width: 1024px) {
      font-size: 1.9rem;
    }
  }

  address {
    font-style: normal;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 3.5rem 0 2rem;
  }

  @keyframes slidein {
    0% {
     transform: translateX(-50%);
    }

    100% {
     transform: translateX(0);
    }
  }

  h1,h2 {
    color: #24214c;

    span {
      display: block;
    }
  }

  h1 {
    padding: 0 0 15px;
    font-size: 3.2rem;
    font-weight: 600;
    margin: 5rem 0 6rem;
    overflow: hidden;
    position: relative;
    text-align: center;

    @media only screen and (min-width: 1024px) {
      font-size: 4.2rem;
    }

    @media only screen and (min-width: 1600px) {
      margin: 5rem 0 8rem;
    }

    &::after {
      border-bottom: 4px solid #fe6521;
      bottom: 0;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      width: 75px;
      /* animation: 400ms forwards alternate slidein; */
      animation-delay: 200ms;
    }
  }

  h2 {
    font-size: 2.6rem;
    margin: 5rem 0;

    @media only screen and (min-width: 1024px) {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 1.8rem;

    @media only screen and (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  p {
    line-height: 160%;
  }

  .subtitle {
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  a {
    transition: color 150ms ease-in;
    color: #393185;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  address, dl {
    line-height: 150%;
  }
  
  img {
    max-width: 100%;
  }

  button {
    cursor: pointer;
  }

  figcaption {
    font-style: italic;
    line-height: 130%;
    margin: 15px 0 0;
    font-size: 1.6rem;
  }

  main {
    margin: 50px auto 100px;
    min-height: 330px;

    iframe {
      border: 0;
      max-width: 100%;
    }

    @media only screen and (min-width: 1024px) {
      margin: 60px 0 150px;
    }

    @media only screen and (min-width: 1600px) {
      margin: 100px 0 150px;
    }
  }

  .row {
    padding: 0 15px;
    margin-inline: auto;
    width: min(100%, 1140px);
    margin: 0 auto;
    max-width: 1140px;
  }

  .box {
    padding: 25px 0;
  }

  .lead {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 4rem;

    @media only screen and (min-width: 800px) {
      font-size: 2.2rem;
      max-width: 86%;
    }
  }

  .text-center {
    text-align: center;
  }

  .no-marg {
    margin: 0;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .col {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &--invert {
      .col--6 {
        &:nth-child(2) {
          order: -1;
        }
      }
    }

    @media only screen and (min-width: 960px) {
      flex-direction: row;

      &--6 {
        width: calc(50% - 20px);
      }
    }

    img {
      margin: 0 auto 20px;
      max-width: 550px;
      width: 100%;
    }
  }

  .btn-fb {
    transition: background 150ms ease-in;
    background: #0866ff;
    border: 2px solid #0866ff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-block;
    line-height: 52px;
    font-weight: 600;
    text-align: center;

    svg {
      path {
        transition: fill 150ms ease-in;
        fill: #fff;
      }
    }

    &:hover,
    &:focus {
      background: white;
      text-decoration: none;

      svg {
        path {
          fill: #0866ff;
        }
      }
    }
  }

  .btn {
    transition: all 150ms ease-in;
    color: #0a24fa;
    border-radius: 5px;
    background: #fff;
    border: 2px solid #0a24fa;
    display: inline-block;
    max-width: 260px;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
      border-color: #fe6521;
      color: #fe6521;
      text-decoration: none;
    }

    &--auto {
      width: max-content;
      max-width: none;
    }
  }

  .btn--send {
    background: hsl(347, 84%, 48%);
    border: 2px solid hsl(347, 84%, 48%);
    color: #fff;

    &:hover,
    &:focus {
      background: #fff;
      color: hsl(347, 84%, 48%);
      text-decoration: none;

      svg {
        path {
          fill: hsl(347, 84%, 48%);
        }
      }
    }

    svg {
      path {
        transition: fill 200ms ease-in;
        fill: #fff;
      }
    }
  }
  
  [data-rmiz-modal-overlay="visible"] {
    background: rgba(0,0,0,.75)!important;
  }
`;
