/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Info = styled.div`
  background: ${({ theme }) => theme.purple};
  color: ${({ theme }) => theme.white};
  font-size: 1.8rem;
  padding: 70px 0;
  display: flex;

  address {
    font-style: normal;

    /* div {
      border-bottom: 1px solid red;
    } */
  }

  .icon {
    padding-left: 45px;
    position: relative;

    svg {
      left: 5px;
      position: absolute;
      top: 2px;

      &.pin {
        left: 8px;
      }

      &.phone {
        left: 8px;
        top: 13px;
      }

      &.clock {
        left: 9px;
        top: 4px;
      }

      path {
        fill: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

const InfoAbout = styled.div`
  display: grid;
  grid-gap: 45px 0;

  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.secondary};
    }
  }

  ul {
    list-style: none;
    margin: 33px 0 0;
    padding: 0;

    li {
      margin: 0 0 9px;

      &.sep {
        padding: 38px 0 0;
        position: relative;

        &::before {
          background: #fff;
          content: '';
          height: 1px;
          left: 0;
          top: 25px;
          position: absolute;
          width: 25px;
        }
      }
    }
  }

  .info-heading {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 30px;
    position: relative;

    &::after {
      bottom: -8px;
      content: '';
      border-bottom: 2px solid ${({ theme }) => theme.secondary};
      position: absolute;
      width: 50px;
      height: 1px;
      margin: 0 auto;
      left: 0;
    }
  }
`;

const Footer = styled.footer`
  padding: 15px 0;

  p {
    font-size: 1.4rem;
    line-height: 150%;
    font-family: ${({ theme }) => theme.font.family.sans};
    margin: 0;
  }
`;

export const FooterComponent = () => (
  <>
    <Info>
      <div className="row">
        <InfoAbout>
          <div>
            <p className="info-heading">Dane kontaktowe</p>
            <address itemScope="itemscope" itemType="http://www.schema.org/LocalBusiness">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 384 512" className="pin">
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                <div itemProp="name">GEO PARTNER <span style={{ letterSpacing: '0.02em' }}>firma geodezyjna</span></div>
                <div itemProp="streetAddress">ul. I Dywizji Wojska Polskiego 6</div>
                <div>
                  <span itemProp="postalCode">10-069</span>
                  {' '}
                  <span itemProp="addressLocality">Olsztyn</span>
                </div>
              </div>

              <div className="icon" style={{ margin: '10px 0' }}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 512 512" className="phone"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  <a href="tel:+48607327303" itemProp="telephone">+48 607 327 303</a> - Radosław Ickiewicz
                </div>
                <div>
                  <a href="tel:+48606659306" itemProp="telephone">+48 606 659 306</a> - Jerzy Podubiński
                </div>
              </div>

              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 512 512">
                  <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                </svg>
                <a href="mailto:geo.partner@wp.pl">geo.partner@wp.pl</a>
              </div>

              <div style={{ margin: '30px 0 5px', fontSize: '2rem', fontWeight: '600' }}>Godziny otwarcia:</div>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" className="clock">
                  <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                </svg>
                poniedziałek - piątek: 7:00 - 15:00
              </div>
            </address>
          </div>

          <div>
            <p className="info-heading">Menu</p>
            <ul>
              <li>
                <Link to="/o-firmie/">O firmie</Link>
              </li>
              <li>
                <Link to="/oferta/">Oferta</Link>
              </li>
              <li>
                <Link to="/realizacje/">Realizacje</Link>
              </li>
              <li>
                <Link to="/galeria/">Galeria</Link>
              </li>
              <li>
                <Link to="/referencje/">Referencje</Link>
              </li>
              <li>
                <Link to="/kontakt/">Kontakt</Link>
              </li>
              <li className="sep">
                <Link to="/cookies/">Cookies</Link>
              </li>
              <li>
                <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
              </li>
            </ul>
          </div>
        </InfoAbout>
      </div>
    </Info>

    <Footer>
      <div className="row">
        <p>© 1998-{new Date().getFullYear()} | Geo Partner</p>
      </div>
    </Footer>
  </>
);

export default FooterComponent;
