/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Burger from '../Burger/Burger';

const Header = styled.header`
  box-shadow: 0 2px 4px hsl(0 0% 30% / 30%);
  width: 100%;
  left: 0;
  top: 0;
  z-index: 11;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};

  ${({ theme }) => theme.mqPad} {
    background: none;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(250,250,250,.8);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      z-index: -1;
    }
  }

  svg {
    max-width: 140px;

    @media only screen and (min-width: 960px) {
      max-width: 100%;
      margin: 3px 0 0;
    }
  }

  .logo {
    position: relative;
    z-index: 4;
  }

  ${({ theme }) => theme.mqMobile} {
    .btn-fb {
      display: none;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;
    /* padding: 5px 15px; */
    padding: 3px 10px 0;
  }

  ${({ theme }) => theme.mqPad} {
    height: auto;
    position: sticky;

    .row {
      align-items: center;
      padding: 0 15px;
    }
  }
`;

const NavWrap = styled.div`
  align-items: center;
  background: white;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: 0;
  padding: 80px 0 0;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};;
  top: 0;
  width: 100%;
  z-index: 3;
  
  ${({ theme }) => theme.mqPad} {
    background: none;
    display: flex;
    height: auto;
    position: relative;
    padding: 0;
    flex-direction: row;
  }
`;

const Nav = styled.nav`
  @media only screen and (max-width: 959px) {
    overflow: auto;
    padding: 0 0 40px;
    width: 100%;
  }

  a {
    color: #323232;
    display: block;
    font-size: 2.4rem;
    font-weight: 600;
    height: auto;
    line-height: normal;
    padding: 3px 0;
    text-decoration: none;

    &:hover,
    &:focus,
    &.active {
      color: ${({ theme }) => theme.primary};
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 30px 20px;
  }

  li {
    margin: 0 0 15px;

    &.disabled {
      display: none;
    }
  }

  ${({ theme }) => theme.mqMobile} {
    a {
      position: relative;
    }

    ul {
      list-style: none;
      margin: 0;

      ul {
        list-style: disc;
        margin: 15px 0 0 25px;

        li {
          margin: 5px 0;
        }

        a {
          font-size: 1.8rem;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  ${({ theme }) => theme.mqPad} {
    margin-left: 40px;
    margin-right: auto;

    a {
      font-weight: 500;
      font-size: 2rem;
    }

    ul {
      padding: 0;
    }

    li {
      &.disabled {
        display: block;

        a {
          color:#000;
          opacity: .25;
          pointer-events: none;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;

      > li {
        display: inline-block;
        margin: 0 20px;
        position: relative;

        &.submenu {
          > a {
            padding-right: 17px;

            &::after {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid #323232;
              content: '';
              position: absolute;
              top: calc(50% - 2px);
              right: 0;
            }
          }

          &:hover,
          &:focus {
            ul {
              display: block;
            }
          }

          ul {
            /* background: ${({ theme }) => theme.white}; */
            border: 1px solid #ddd;
            border-top: 0;
            box-shadow: 0 5px 5px -5px hsl(0 0% 30% / 30%);
            display: none;
            left: -18px;
            padding: 5px 20px;
            position: absolute;
            top: 70px;
            width: 250px;
            z-index: -1;
            
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background: rgba(250,250,250,.8);
              -webkit-backdrop-filter: blur(20px);
              backdrop-filter: blur(20px);
              z-index: -1;
            }

            ${({ theme }) => theme.mqLaptop} {
              top: 80px;
            }

            li {
              display: block;
              margin: 0;

              & + li {
                border-top: 1px solid #ddd;
              }
            }

            a {
              display: block;
              font-size: 1.6rem;
              height: auto;
              line-height: normal;
              padding: 10px 0;
            }
          }
        }
      }
    }

    a {
      ${({ theme }) => theme.mqTablet} {
        line-height: 70px;
        padding: 0;
      }

      ${({ theme }) => theme.mqLaptop} {
        line-height: 80px;
        font-size: 2rem;
      }
    }
  }
`;

export const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Header isOpen={isOpen}>
      <div className="row">
        <Link
          to="/"
          title="Geo Partner - usługi geodezyjne"
          className="logo"
          activeClassName="active"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="170" height="80" viewBox="0 0 170 80">
            <defs>
              <clipPath id="clip-Web_1920_4">
                <rect width="170" height="80" />
              </clipPath>
            </defs>
            <g id="geopartner" data-name="Web 1920 – 1" clipPath="url(#clip-Web_1920_4)">
              {/* <rect width="170" height="80" fill="#fff" /> */}
              <g id="geopartner_1" data-name="Group 1" transform="translate(13685 17557.211)">
                <path id="geo_Path_1" data-name="Path 1" d="M3245.22,5907.311h46.443v36.607H3245.22Z" transform="translate(-16918.283 -23454.818)" fill="#0a25f9" fillRule="evenodd" />
                <path id="geo_Path_2" data-name="Path 2" d="M18786.371,5907.311h46.441v36.607h-46.441Z" transform="translate(-32358.896 -23454.818)" fill="#0a25f9" fillRule="evenodd" />
                <path id="geo_Path_3" data-name="Path 3" d="M3312.719,12012.059h1.936a8.293,8.293,0,0,1,1.123.07,11.069,11.069,0,0,1,1.123.217,3.389,3.389,0,0,1,2.649,2.7,11.39,11.39,0,0,1,.317,2.754c0,2.818-.618,4.607-1.858,5.383a4.4,4.4,0,0,1-1.836.605c-.243.027-.487.051-.731.066s-.491.027-.744.027h-8.406v3.748h-4.673v-15.574Zm-6.426,4.014v3.8h7.314a1.34,1.34,0,0,0,1.284-.553,3.04,3.04,0,0,0,.3-1.465,2.169,2.169,0,0,0-.335-1.3,1.169,1.169,0,0,0-1-.455l-1.984-.027Z" transform="translate(-16974.314 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_4" data-name="Path 4" d="M6150.8,12012.059h5.927l8.449,15.574h-5.285l-1.428-2.686h-9.447l-1.378,2.686h-5.292Zm.075,9.248h5.686l-2.78-5.289Z" transform="translate(-19796.668 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_5" data-name="Path 5" d="M10029.679,12012.059c.516,0,.864,0,1.056.018s.474.045.847.082a3.53,3.53,0,0,1,2.919,1.582,4.181,4.181,0,0,1,.432,1.09,10.107,10.107,0,0,1,.178,1.135,10.316,10.316,0,0,1,.065,1.182,6.369,6.369,0,0,1-.508,2.889,3.905,3.905,0,0,1-2.425,1.541,3.974,3.974,0,0,1,2.391,1.334,6,6,0,0,1,.465,2.813v1.908h-4.673l-.027-1.443a3.523,3.523,0,0,0-.344-1.928c-.256-.342-.856-.508-1.809-.508h-7.4v3.879h-4.674v-15.572Zm-8.835,4.012v3.666h7.993a3.328,3.328,0,0,0,.526-.037,1.526,1.526,0,0,0,.439-.143c.464-.209.7-.771.7-1.693a1.83,1.83,0,0,0-.482-1.391,2.1,2.1,0,0,0-1.415-.35l-1.362-.053Z" transform="translate(-23645.428 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_6" data-name="Path 6" d="M13194.482,12012.059v4.014h-6.711v11.561h-4.672v-11.561h-6.709v-4.014Z" transform="translate(-26785.207 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_7" data-name="Path 7" d="M16288.178,12012.059l8.822,11.613v-11.613h4.672v15.574h-7.42l-8.82-11.641v11.641h-4.672v-15.574Z" transform="translate(-29869.494 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_8" data-name="Path 8" d="M20009.309,12012.059h16.84v3.641h-12.164v2.1h11.488v3.375h-11.488v2.818h12.309v3.641h-16.984Z" transform="translate(-33573.922 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_9" data-name="Path 9" d="M23123.84,12012.059c.516,0,.867,0,1.055.018s.473.045.848.082a3.533,3.533,0,0,1,2.922,1.582,4.142,4.142,0,0,1,.43,1.09,11.957,11.957,0,0,1,.242,2.316,6.356,6.356,0,0,1-.508,2.889,3.905,3.905,0,0,1-2.426,1.541,3.958,3.958,0,0,1,2.391,1.334,6,6,0,0,1,.465,2.813v1.908h-4.672l-.027-1.443a3.5,3.5,0,0,0-.344-1.928c-.254-.342-.855-.508-1.809-.508h-7.4v3.879h-4.672v-15.572Zm-8.836,4.012v3.666H23123a3.281,3.281,0,0,0,.527-.037,1.476,1.476,0,0,0,.438-.143c.469-.209.7-.771.7-1.693a1.838,1.838,0,0,0-.484-1.391,2.1,2.1,0,0,0-1.414-.35l-1.359-.053Z" transform="translate(-36654.883 -29520.076)" fill="#0a25f9" />
                <path id="geo_Path_10" data-name="Path 10" d="M4983.756,14669.3h2.667v-4.795h1.44v-1.914h-1.44v-.2c0-.381.138-.537.639-.537h.8v-1.621a17.425,17.425,0,0,0-1.75-.074c-1.739,0-2.358.49-2.358,1.719v.713h-1.237v1.914h1.237Z" transform="translate(-18644.342 -32151.045)" fill="#fe6521" />
                <path id="geo_Path_11" data-name="Path 11" d="M5922.38,14680.6h2.666v-6.709h-2.666Zm0-7.371h2.666v-1.7h-2.666Z" transform="translate(-19578.121 -32162.35)" fill="#fe6521" />
                <path id="geo_Path_12" data-name="Path 12" d="M6532.48,15014.125h2.666v-2.516c0-1.436,1.014-1.8,1.963-1.8a4.71,4.71,0,0,1,1.333.244v-2.746a5.3,5.3,0,0,0-.875-.076,2.911,2.911,0,0,0-2.484,1.475h-.021v-1.287h-2.581Z" transform="translate(-20184.275 -32495.867)" fill="#fe6521" />
                <path id="geo_Path_13" data-name="Path 13" d="M7571.28,15014.125h2.667v-3.559c0-.834.352-1.127.905-1.127.471,0,.716.209.716.895v3.791h2.581v-3.559c0-.834.353-1.127.907-1.127.469,0,.715.209.715.895v3.791h2.666v-4.625a2.076,2.076,0,0,0-2.3-2.271,2.361,2.361,0,0,0-2.145,1.119,1.84,1.84,0,0,0-1.931-1.119,2.424,2.424,0,0,0-2.177,1.105h-.021v-.918h-2.58Z" transform="translate(-21216.357 -32495.867)" fill="#fe6521" />
                <path id="geo_Path_14" data-name="Path 14" d="M9434.343,14996.265c.118-.65.746-.76,1.344-.76.769,0,1.238.209,1.238.65,0,.381-.172.516-.715.553l-2.251.123c-1.974.1-2.73.883-2.73,2.109,0,1.719,1.13,2.27,2.923,2.27a3.755,3.755,0,0,0,2.923-.932,2.975,2.975,0,0,0,.1.662h2.73a1.97,1.97,0,0,1-.246-1.043v-3.238c0-2-1.075-2.7-3.8-2.7a6.766,6.766,0,0,0-3.029.467,2.258,2.258,0,0,0-1.153,1.84Zm2.646,1.916c0,1.152-.992,1.41-1.887,1.41-.674,0-.994-.281-.994-.76,0-.43.225-.615.727-.664a20.31,20.31,0,0,0,2.154-.281Z" transform="translate(-23064.27 -32482.686)" fill="#fe6521" />
                <path id="geo_Path_15" data-name="Path 15" d="M11557.545,15013.561c0,.934-.361,1.375-1.463,1.375a1.855,1.855,0,0,1-.885-.172.644.644,0,0,1-.328-.49h-2.945c.256,1.68,1.311,2.355,4.223,2.355,2.666,0,4.066-.836,4.066-2.982v-6.23h-2.582v.76a3.077,3.077,0,0,0-2.4-.947c-2,0-3.529,1.131-3.529,3.242s1.227,3.238,3.529,3.238a3.189,3.189,0,0,0,2.314-.725Zm0-3.029c0,.736-.395,1.336-1.537,1.336-1.109,0-1.514-.6-1.514-1.336,0-.7.4-1.312,1.514-1.312C11557.15,15009.219,11557.545,15009.83,11557.545,15010.531Z" transform="translate(-25171.025 -32495.867)" fill="#fe6521" />
                <path id="geo_Path_16" data-name="Path 16" d="M13018.662,14996.758c.137-.848.66-1.176,1.6-1.176s1.525.439,1.578,1.176Zm3,2.172a1.455,1.455,0,0,1-1.385.588c-1.176,0-1.611-.734-1.611-1.213h5.865c.033-3.35-1.832-4.342-4.437-4.342s-4.16,1.3-4.16,3.617c0,2.453,1.6,3.633,4.406,3.633a5.574,5.574,0,0,0,2.826-.613,2.733,2.733,0,0,0,1.334-1.67Z" transform="translate(-26625.785 -32482.688)" fill="#fe6521" />
                <path id="geo_Path_17" data-name="Path 17" d="M14453.258,14997.58c0-1.066.457-1.631,1.523-1.631s1.525.564,1.525,1.631-.449,1.645-1.525,1.645S14453.258,14998.658,14453.258,14997.58Zm-2.8,0c0,2.479,1.3,3.633,4.32,3.633s4.318-1.154,4.318-3.633-1.318-3.617-4.318-3.617C14451.762,14993.963,14450.461,14995.127,14450.461,14997.58Z" transform="translate(-28051.035 -32482.688)" fill="#fe6521" />
                <path id="geo_Path_18" data-name="Path 18" d="M15890.737,14680.6h2.582v-9.066h-2.668v3.119h-.02a2.672,2.672,0,0,0-2.316-.945,3.594,3.594,0,0,0,0,7.166,2.727,2.727,0,0,0,2.4-.982h.021Zm-.086-3.311c0,.971-.437,1.521-1.461,1.521a1.346,1.346,0,0,1-1.461-1.521,1.375,1.375,0,0,1,1.451-1.523A1.329,1.329,0,0,1,15890.651,14677.294Z" transform="translate(-29476.291 -32162.35)" fill="#fe6521" />
                <path id="geo_Path_19" data-name="Path 19" d="M17322.281,14996.758c.141-.848.664-1.176,1.6-1.176s1.525.439,1.576,1.176Zm3,2.172a1.458,1.458,0,0,1-1.387.588c-1.172,0-1.611-.734-1.611-1.213h5.867c.031-3.35-1.836-4.342-4.436-4.342s-4.162,1.3-4.162,3.617c0,2.453,1.6,3.633,4.406,3.633a5.579,5.579,0,0,0,2.826-.613,2.743,2.743,0,0,0,1.332-1.67Z" transform="translate(-30901.563 -32482.688)" fill="#fe6521" />
                <path id="geo_Path_20" data-name="Path 20" d="M18745.832,15042.391h6.973v-2.135h-3.223v-.025l3.129-2.783v-1.768h-6.687v2.063h3.1v.021l-3.293,2.936Z" transform="translate(-32318.619 -32524.137)" fill="#fe6521" />
                <path id="geo_Path_21" data-name="Path 21" d="M19830.059,15044.686c.5.049,1.215.109,1.813.109,1.449,0,1.746-.355,2.176-1.312l3.273-7.8h-2.8l-1.285,3.447-1.293-3.447h-2.8l2.711,6.17-.148.369c-.227.49-.43.516-1,.516a3.535,3.535,0,0,1-.656-.035Z" transform="translate(-33394.926 -32524.137)" fill="#fe6521" />
                <path id="geo_Path_22" data-name="Path 22" d="M21014.711,14682.937a10.988,10.988,0,0,0,1.418.074c1.5,0,2.355-.258,2.355-2.455v-6.66h-2.664v6.184c0,.674-.137.725-.617.725h-.492Zm1.109-9.7h2.664v-1.7h-2.664Z" transform="translate(-34572.824 -32162.35)" fill="#fe6521" />
                <path id="geo_Path_23" data-name="Path 23" d="M21796.289,15014.125h2.664v-3.092c0-1.115.523-1.594,1.242-1.594.637,0,1.02.367,1.02,1.215v3.471h2.668v-4.268a2.356,2.356,0,0,0-2.582-2.629,2.917,2.917,0,0,0-2.406,1.02h-.027v-.832h-2.578Z" transform="translate(-35349.344 -32495.867)" fill="#fe6521" />
                <path id="geo_Path_24" data-name="Path 24" d="M23108.617,14996.265c.117-.65.746-.76,1.344-.76.766,0,1.234.209,1.234.65,0,.381-.172.516-.715.553l-2.25.123c-1.973.1-2.73.883-2.73,2.109,0,1.719,1.129,2.27,2.922,2.27a3.764,3.764,0,0,0,2.926-.932,3.3,3.3,0,0,0,.094.662h2.73a2,2,0,0,1-.246-1.043v-3.238c0-2-1.074-2.7-3.8-2.7a6.756,6.756,0,0,0-3.027.467,2.247,2.247,0,0,0-1.152,1.84Zm2.645,1.916c0,1.152-.992,1.41-1.887,1.41-.672,0-1-.281-1-.76,0-.43.227-.615.727-.664a20.51,20.51,0,0,0,2.156-.281Z" transform="translate(-36650.082 -32482.686)" fill="#fe6521" />
                <path id="geo_Path_25" data-name="Path 25" d="M10827.619,5914.491h49.045v36.548h-49.045Z" transform="translate(-24451.631 -23461.951)" fill="#fe6521" fillRule="evenodd" />
                <path id="geo_Path_26" data-name="Path 26" d="M3941.512,6421.146a2.969,2.969,0,0,0-.736-2.15,2.7,2.7,0,0,0-2.05-.762c-.862,0-1.833-.009-2.912-.017s-2.117-.025-3.129-.033l-5.573.05a7.279,7.279,0,0,0-4.016.862,3.261,3.261,0,0,0-1.113,1.4,5.686,5.686,0,0,0-.4,2.269l-.1,3.163a29.325,29.325,0,0,0,.192,3.631q.327,3.139,2.945,3.431a27.33,27.33,0,0,0,3.38.151h9.673q4.142,0,4.142-2.694v-.972h-11.145v-6.024h20.115v1.056a93.416,93.416,0,0,1-.284,9.664,7.192,7.192,0,0,1-1.941,3.79,6.732,6.732,0,0,1-3.874,2.066c-.527.1-1.08.192-1.674.285a11.785,11.785,0,0,1-1.64.134c-.468,0-2.435.059-5.882.176-1.975.066-3.506.1-4.585.1h-6.25a21.2,21.2,0,0,1-5.883-.611,7.442,7.442,0,0,1-4.61-3.515,11.1,11.1,0,0,1-1.129-2.937,16.546,16.546,0,0,1-.41-3.849c0-1.1-.009-1.983-.025-2.653q-.1-4.053-.1-4.418l.025-2.192a10.8,10.8,0,0,1,.142-1.715,10.113,10.113,0,0,1,.494-1.774q1.619-4.983,7.573-5.991c.477-.083,1.029-.167,1.682-.242a12.64,12.64,0,0,1,1.505-.126l7.4-.1c1.506,0,3.054.009,4.652.033s3.305.05,5.1.066q4.632,0,7.029,2.151a8.051,8.051,0,0,1,2.4,6.309v1.983Z" transform="translate(-17581.236 -23954.854)" fill="#fefefe" />
                <path id="geo_Path_27" data-name="Path 27" d="M19377.984,6440.622c-.672,0-1.355-.009-2.064-.033s-1.291-.034-1.725-.042a9.4,9.4,0,0,1-3.65-.67,7.615,7.615,0,0,1-2.744-1.908,6.056,6.056,0,0,1-1.4-2.032,14.137,14.137,0,0,1-.9-3.74,45.183,45.183,0,0,1-.318-5.815v-3.824a23.573,23.573,0,0,1,.234-3.489,18.353,18.353,0,0,1,.7-3,6.9,6.9,0,0,1,4.514-4.511,19.515,19.515,0,0,1,4.537-.71q3.264-.239,8.586-.242h6.453q6.762,0,9.078.8a7.724,7.724,0,0,1,5.344,6.853c.043.477.094,1.112.143,1.907s.074,1.59.074,2.377l.053,4.845a45.086,45.086,0,0,1-.217,4.928,11.954,11.954,0,0,1-.937,3.465,7.521,7.521,0,0,1-3.121,3.606,10.084,10.084,0,0,1-5.225,1.238l-4.561.1Zm11.447-7.531a25.166,25.166,0,0,0,3.211-.15,3.124,3.124,0,0,0,2.555-1.74q.765-1.494.762-6.4a19.35,19.35,0,0,0-.242-3.6,3.04,3.04,0,0,0-2.211-2.577,13.327,13.327,0,0,0-1.355-.268,9.066,9.066,0,0,0-1.49-.126l-5.75-.05h-3.234q-.759,0-2.051.024t-1.582.025a4.08,4.08,0,0,0-1.867.386,3.327,3.327,0,0,0-1.271,1.146c-.527.787-.783,3.172-.783,7.154,0,2.8.457,4.561,1.371,5.288q.976.841,5.471.837Z" transform="translate(-32933.961 -23954.852)" fill="#fefefe" />
                <path id="geo_Path_28" data-name="Path 28" d="M11413.555,6424.188h9.072v-6.821h-9.072v-5.159h9.072v-6.737h-25.076v30.2h25.076v-6.319h-9.072Z" transform="translate(-25017.873 -23949.754)" fill="#fefefe" fillRule="evenodd" />
                <path id="geo_Path_29" data-name="Path 29" d="M2024.405,4749.1h149.73a6.434,6.434,0,0,1,6.475,6.36v60.4a6.436,6.436,0,0,1-6.475,6.36h-9.209v-3.629h9.209a2.774,2.774,0,0,0,2.781-2.731v-60.4a2.771,2.771,0,0,0-2.781-2.731h-149.73a2.768,2.768,0,0,0-2.782,2.731v60.4a2.77,2.77,0,0,0,2.782,2.731h11.63v3.629h-11.63a6.434,6.434,0,0,1-6.476-6.36v-60.4A6.431,6.431,0,0,1,2024.405,4749.1Z" transform="translate(-15698.93 -22304.1)" fill="#fe6521" fillRule="evenodd" />
              </g>
            </g>
          </svg>
        </Link>

        <NavWrap isOpen={isOpen}>
          <Nav>
            <ul>
              <li>
                <Link to="/oferta/" activeClassName="active" onClick={toggleNavigation}>Oferta</Link>
              </li>
              <li>
                <Link to="/realizacje/" activeClassName="active" onClick={toggleNavigation}>Realizacje</Link>
              </li>
              <li>
                <Link to="/o-firmie/" activeClassName="active" onClick={toggleNavigation}>O firmie</Link>
              </li>
              <li>
                <Link to="/referencje/" activeClassName="active" onClick={toggleNavigation}>Referencje</Link>
              </li>
              <li>
                <Link to="/galeria/" activeClassName="active" onClick={toggleNavigation}>Galeria</Link>
              </li>
              <li>
                <Link to="/kontakt/" activeClassName="active" onClick={toggleNavigation}>Kontakt</Link>
              </li>
            </ul>
          </Nav>
        </NavWrap>

        <Burger isOpen={isOpen} setIsOpen={setIsOpen} title="Menu" />
      </div>
    </Header>
  );
};

export default HeaderComponent;
