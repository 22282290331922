export const theme = {
  primary: '#0a24fa',
  primaryLight: 'hsl(347, 73%, 71%);',
  secondary: '#fe6521',
  white: 'hsl(0, 0%, 100%)',
  grey: 'hsl(0, 0%, 37%)',
  purple: '#24214c',
  black: 'hsl(0, 0%, 0%)',
  font: {
    size: {
      heading: '5rem',
      headingMobile: '5.3rem',
      headingSmall: '4.2rem',
      paragraph: '1.6rem',
      caption: '1.35rem',
    },
    family: {
      // cormorant: '"Cormorant Garamond", serif',
      sans: '"Lato", sans-serif',
    },
  },
  mqMobile: '@media (max-width: 767px)',
  mqTablet: '@media (min-width: 768px)',
  mqPad: '@media (min-width: 960px)',
  mqLaptop: '@media (min-width: 1024px)',
  mqDesktop: '@media (min-width: 1440px)',
};
